.slides-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.slides-title-main {
    display: flex;
    align-items: center;
}

.slide {
    display: flex;
    justify-content: space-between;
}
