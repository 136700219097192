#app-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-modal-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}

.app-modal-content {
    width: 30%;
    height: fit-content;
    background: #fff;
    border-radius: 2px;
    padding-bottom: 50px;
    margin-top: 20vh;
    /* position: absolute;
  width: 50vw;
  height: 98vh;
  left: 25vw;
  top: 1vh; */
    /* overflow-y: auto; */
}

.app-modal-content.update {
    height: 85vh;
    top: 5vh;
}

/* .app-modal-content.app-modal-confirm, .app-modal-content.app-modal-small {
  width: 20vw;
  height: 20vh;
  left: 38vw;
  top: 25vh;
} */

.app-modal-header {
    height: 45px;
    align-items: center;
    border-bottom: 1px solid #c5c5c5;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.3px;
}

.app-modal-header i {
    cursor: pointer;
    opacity: 0.7;
}

.app-modal-header i:hover {
    opacity: 1;
}

.app-modal-body {
    padding: 18px 2em;
}

.app-modal-footer {
    padding: 0 24px;
    text-align: right;
}

.app-modal-footer.btns {
    display: flex;
    justify-content: flex-end;
}

.app-modal-footer .btn + .btn {
    margin-left: 5px;
}

.app-modal-content.app-modal-description {
    width: 35%;
    left: 30%;
    height: fit-content;
    top: 5em;
    padding-bottom: 2em;
}

.app-modal-description .app-modal-body {
    padding: 24px;
}

.app-modal-description textarea {
    padding: 8px;
    min-height: 25em;
    line-height: 1.4;
}

.submit-btn {
    display: flex;
    align-items: center;
    justify-content: start;
}
/* 
@media only screen and (max-width: 1700px) {
  .app-modal-content.app-modal-confirm, .app-modal-content.app-modal-small {
    width: 25vw;
    height: 24vh;
    left: 36vw;
    top: 25vh;
  }
} */

/* @media only screen and (max-width: 1600px) {
  .app-modal-content.app-modal-confirm, .app-modal-content.app-modal-small {
    width: 40vw;
    height: 30vh;
    left: 30vw;
    top: 25vh;
  }
}

@media only screen and (max-width: 1000px) {
  .app-modal-content.app-modal-confirm, .app-modal-content.app-modal-small {
    width: 40vw;
    height: 35vh;
    left: 30vw;
    top: 25vh;
  }
} */
