.project-status-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 10px;
}

.projects-header {
    margin-bottom: 5px;
}

.projects-header,
.projects-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.projects-header-section a {
    margin-left: 5px;
}

._27KXr i {
    top: 2px;
}

.narrow-col {
    width: 95px;
    padding: 5px 10px !important;
}

.projects-table {
    margin: 1.5rem 0 2rem;
}

/* SEARCHABLE COLUMN */
.input-field.searchable {
    margin: 0;
    width: 110px;
}

.searchable i {
    position: absolute;
    font-size: 18px;
    top: 2px;
    left: 3px;
    color: silver;
}

.searchable ::placeholder {
    color: #333;
    font-weight: bold;
}

.searchable input[type='text']:not(.browser-default) {
    margin: 0;
    width: 90%;
    border: none;
    font-size: 15px;
    height: auto;
    padding-left: 25px;
    font-weight: bold;
}

.searchable input[type='text']:not(.browser-default):focus:not([readonly]) {
    border-bottom: none !important;
    box-shadow: none !important;
}

.progress {
    position: fixed;
    width: 100%;
    margin: -4px 0 0 0;
    bottom: 0;
    left: 0;
    height: 0.5rem;
    background: linear-gradient(to right, #6699cc 0%, #996699 45%, #ff3333 80%);
    opacity: 0.5;
}

.page-input {
    display: flex;
    justify-items: center;
    align-items: center;
    margin: 0 10px;
}

.page-input input {
    width: 2rem;
    height: 32px;
    margin-right: 10px;
    padding: 4px;
    text-align: center;
}
