.task-list {
    display: block;
}

.task-list.segment-section {
    padding: 0 2rem;
}

.task {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin: 5px 0;
}

.task-icon {
    font-size: 18px;
    margin-right: 5px;
}

.randomization-options {
    margin: 8px 0;
}

.randomization-options p + p {
    margin-left: 10px;
}

.randomization-positions {
    border: 1px solid #99bbdc;
    width: fit-content;
}

.randomization-block {
    text-align: center;
}

.randomization-block:nth-child(odd) {
    background: #6699cc30;
}

.randomization-block > p {
    border-bottom: 1px dashed #99bbdc;
    font-weight: 500;
}

.randomization-block * {
    padding: 5px;
}

.randomization-block select.browser-default {
    background: transparent;
    border: none;
    appearance: auto;
}

.task .collapsible-body {
    padding: 0;
}

.task .segment {
    margin: 0;
}

.task .collapsible-header {
    background: initial;
}

.task-type {
    width: calc(20% - 2rem);
}

.task-type select {
    text-transform: capitalize;
}

.task-options {
    width: 70%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    gap: 25px;
}

.show-generic-instructions-wrapper {
    margin-left: 20px;
    margin-top: 15px;
    padding-top: 10px;
}

.remove-task-position {
    font-size: 0.9em;
}

.introslides {
    margin: 2rem 0;
}
div.introslides i.material-icons.disabled.events-none {
    color: var(--eyesee-gray);
    opacity: 0.65;
}

.introslides .segment-section-title {
    margin: 8px 0;
}

.introslide-wrapper {
    position: relative;
    margin: 10px 0;
    padding-bottom: 10px;
    padding-top: 10px;
}
.introslide-wrapper::before {
    position: absolute;
    left: 0;
    /* bottom: 0; */
    height: 3px;
    width: 100%;
    content: '';
    background-image: linear-gradient(
        to right,
        #6699cc 0%,
        #996699 45%,
        #ff3333 80%
    );
}

.introslide-delete {
    position: absolute;
    z-index: 5;
    top: 40px;
    right: 10px;
}

.introslides-options i {
    margin-left: 1em;
}

#app .collapsible-header i.drag-indicator {
    color: #a0a0a0 !important;
    margin-right: -5px;
}

.randomization-groups {
    margin-left: 19%;
}

.groups .randomization-block {
    margin: 10px 19%;
}

.ungrouped-block select.browser-default {
    width: 15%;
    margin: 8px;
}

.groups .randomization-block span {
    margin: 0px 10px;
}

.randomization-groups p {
    width: 15%;
    text-align: center;
    margin: 8px;
    background: white;
    border: 1px solid #dbdbdb;
}

label.label-stimuli-logic {
    font-size: 0.8rem !important;
    position: relative;
    top: 20px;
}

.eyesee-red-button {
    background-color: var(--eyesee-red);
}

.create-button--move-right {
    margin-left: 2rem;
}
.create-button--move-right.events-none {
    background-color: var(--eyesee-gray);
}
.create-button--move-left {
    margin-right: 2rem;
}
.create-button--move-left.events-none {
    background-color: var(--eyesee-gray);
}
.move-recalibration-checkbox-left {
    margin-left: 50px;
}

.task-group--align-center {
    align-items: center;
    justify-content: flex-start;
}

.is-not-pinned {
    transform: skew(-0.08turn, 25deg);
    position: relative;
    left: 2px;
}

.click-task-exit {
    display: flex;
    align-items: center;
}

.exit-trigger {
    width: 40ch;
    margin-right: 20px;
}

.click-task-exit-additonal {
    display: flex;
}

.click-task-exit-additional input[type='number'] {
    width: 4ch;
    text-align: center;
    margin: 0 5px;
}

.survey-task-url-wrapper {
    display:flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.survey-task-url-wrapper .label-wrapper {
    width:10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.survey-task-url-wrapper--input {
    width:65%
}
.vs-task-button-wrapper {
    padding: 0px 1em;
    display: flex;
    justify-content: space-between;
    width:15%;
}
.vs-task-button-wrapper .discard-btn {
    margin-left:1em
}

.btn-small.eyesee-red-button:hover {
    background: var(--eyesee-red);
}

.eye-wrapper {
    margin-bottom: 1em;
}