.all-stimuli {
    margin: 0;
}

.all-stimuli .stimuli-list > div {
    flex-wrap: wrap;
    margin-left: 1rem;
}
.all-stimuli .stimuli-list {
    display: flex;
    justify-content: center;
    width: 100%;
}
.all-stimuli .stimuli-list .stimulus-wrapper {
    width: 15%;
}

.stimulus {
    width: 100%;
    height: 150px;
    /* background-size: cover;
  background-position: cover;
  background-repeat: no-repeat;
  position: relative; */
    border: 1px solid #dbdbdb;
}

.stimulus-preview {
    position: relative;
    width: 100%;
    height: 150px;
}

.stimulus-wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-bottom: 5px;
}

.stimulus-header {
    display: flex;
    flex-direction: column;
}
.stimulus-header .switch {
    display: flex;
    text-align: center;
    font-size: 10px;
    align-items: center;
    padding: 0 5px;
    justify-content: flex-end;
    margin-top: 5px;
}
.switch label .lever {
    width: 20px;
    height: 10px;
    margin-right: 5px;
    margin-left: 5px;
}
.switch label input[type='checkbox']:checked + .lever:before,
.switch label input[type='checkbox']:checked + .lever:after {
    left: 10px;
}
.switch label .lever:before,
.switch label .lever:after {
    width: 10px;
    height: 10px;
    top: -1px;
}
.logic-number {
    text-align: center;
}

.task .stimuli {
    /* margin: 2rem 0; */
    margin: 4rem auto;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 4rem);
    overflow: auto;
    padding: 0;
    justify-content: center;
}

.stimuli .task.collapsible {
    width: 100%;
}

.stimuli-exposure {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
    margin-top: 5px;
}

.task .stimuli input[type='number'] {
    width: 60%;
    text-align: right;
}

.multiselect-exposure-time-wrapper .select-wrapper {
    width: 95%;
}
.multiselect-click-exposure-time-wrapper .select-wrapper {
    width: 95%;
}
.multiselect-hidden {
    display: none !important;
}
.stimulus-visibility {
    width: 20%;
}
.multiselect-wrapper {
    width: 100%;
}
.stimulus-visibility--small {
    width: 18%;
    margin-left: 15px;
}
.stimulus-visibility label,
.web-max-exposure-wrapper label {
    font-size: 0.7rem;
}

.web-max-exposure-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 50%;
}

.web-timeout-input {
    width: 45%;
}

.multiselect-exposure-time-wrapper,
.multiselect-click-exposure-time-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.multiselect.events-none {
    pointer-events: none;
}

.stimulus-name {
    padding: 5px;
    /* margin: 5px 0; */
    /* background-color: #f3f6f9; */
}

.cell-tags {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    flex-wrap: wrap;
    flex-direction: column;
}

.cell-tag {
    background-color: #eaeff5;
    padding: 5px 10px;
    border-radius: 25px;
    margin: 2px;
    font-size: 0.9em;
}
.preload-container {
    padding-left: 35px;
}

.preload-container span.preload-container--label {
    padding-left: 30px;
}

.stimuli-preview-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.stimuli-preview-container.stimuli-preview-container--between {
    justify-content: space-between;
}

.stimuli-preview-container > div {
    width: 25%;
}
.create-stimuli-logic-button--margin {
    margin: 2rem 0px;
}
.stimuli-logic-create-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.stimuli-list-in-group-container.collapsible-body {
    width: 95%;
    margin: 10px auto;
    border-bottom: 0px !important;
}

.select-exit-type-wrapper {
    padding: 10px 0px;
    font-size: 10px;
}

.select-exit-type-wrapper li > a,
.dropdown-content li > span,
.select-exit-type-wrapper input {
    font-size: 14px !important;
}

.exit-stimulus-label {
    margin: 20px 0px;
}

.select-exit-type option {
    font-size: 14px;
}

.web-task-stimuli-input-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.web-task-height-px {
    margin-left: 10px;
}

.web-task-url {
    justify-content: center;
}

.web-task-url a {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.task .stimuli .web-task-stimuli-input-container .small-letters {
    font-size: 13px;
}
.task .stimuli .web-task-stimuli-input-container input[type='number'] {
    width: 100%;
}

.web-task-stimuli-wrapper {
    padding-top: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}
