.user-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5em;
  margin: 0 2.2em;
}

.user-menu img {
  height: 3em;
  border-radius: 50%;
  margin-right: 10px;
}

.user-menu p {
  color: #999999;
}

#user-dropdown img {
  height: 1.8em;
  border-radius: 0;
}
