#navigation.eyesee-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 0px 5px #999999;
  padding: 0 5px;
  background: #fff;
  
}

.eyesee-header::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  content: '';
  background-image: linear-gradient(to right, #6699CC 0%, #996699 45%, #FF3333 80%);
}

#navigation .logo {
  margin-left: calc(5% - 68px);
}

#navigation .logo img {
  height: 48px;
  margin-top: 5px;
}

#navigation .menu {
  display: flex;
}

#navigation .menu-item {
  margin: 0 5px;
  cursor: pointer;
}

.menu-item .material-icons {
  font-size: 40px;
}

.app .tabs {
  margin: 1em 0px;
}
#innovation-hub {
  height: 50px;
}
