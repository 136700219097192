.cell {
    padding: 5px 20px;
    border: 1px solid #dbdbdb;
    display: flex;
    background: #fff;
}

.cell + .cell {
    border-top: none;
}

.cell:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.cell:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.cell-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.cell-statistics {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: flex-end;
}

.cell-name {
    display: flex;
}

.cell-name p {
    margin-right: 7px;
}

.events-none {
    pointer-events: none;
}

.cell-slider-container {
    position: relative;
}

.cell-slider-value-display {
    position: absolute;
    bottom: 30px;
    background-color: #26a69a;
    border-radius: 50%;
    color: white;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 12px;
}

.cell-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

form .cell-wrapper {
    justify-content: start;
}

.switch-container {
    width: 15%;
}

.cell-slider-wrapper {
    width: 85%;
}

.query_stats_text {
    color: var(--eyesee-gray) !important;
}
