select {
    height: 2rem;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0 5px;
    border: 1px solid #dbdbdb;
}

.text-input input[type='text'] {
    border: 1px solid #dbdbdb;
    height: 2.2rem;
    font-size: 1rem;
    line-height: 1rem;
    padding: 5px 15px;
    margin: 0;
    background: #fff;
}

.error-msg {
    color: red;
    margin-left: 5px;
    display: inline;
}
.error-msg.no-margin-left {
    margin-left: 0px;
}

.error-msg.hidden {
    display: none;
}
.stimulus-visibility--small .error-msg {
    font-size: 9px;
}
.error-msg__no-left-margin {
    margin-left: 0px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}
.flex-column .error-msg {
    margin-left: 0px;
}

#app .collapsible {
    box-shadow: none;
    border: 1px solid #dbdbdb;
}

#app .collapsible-body {
    background: #fff;
}

#app .collapsible-header i {
    font-size: 20px;
    margin-right: 5px;
    color: hsl(0 0% 40% / 1);
}

#app .collapsible-header span {
    color: hsl(0 0% 25% / 1);
}

.tabs .tab a {
    color: hsl(210 50% 80% / 1);
}

.tabs .tab a:hover,
.tabs .tab a.active {
    color: var(--eyesee-blue);
    background: aliceblue;
}

.tabs .indicator {
    background-color: var(--eyesee-blue);
}

.tabs .tab a:focus,
.tabs .tab a:focus.active {
    background-color: #e3f2fd54;
}

#app [type='radio']:not(:checked) + span,
[type='radio']:checked + span,
[type='checkbox'] + span:not(.lever) {
    padding-left: 22px;
}

#app [type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span:before,
[type='radio'].with-gap:checked + span:after {
    border: 2px solid var(--eyesee-blue);
}

#app [type='checkbox'].filled-in:checked + span:not(.lever):after {
    border: 2px solid var(--eyesee-blue);
    background-color: var(--eyesee-blue);
}
#app [type='checkbox'].filled-in.disabled:checked + span:not(.lever):after {
    border: 2px solid var(--eyesee-gray);
    background-color: var(--eyesee-gray);
}

#app .switch label input[type='checkbox']:checked + .lever {
    background-color: #6699cc80;
}
#app .switch.disabled label input[type='checkbox']:checked + .lever {
    background-color: var(--eyesee-gray);
    opacity: 0.5;
}

#app .switch label input[type='checkbox']:checked + .lever:after {
    background-color: var(--eyesee-blue);
}
#app .switch.disabled label input[type='checkbox']:checked + .lever:after {
    background-color: var(--eyesee-gray);
}
#app [type='radio']:not(:checked) + span:before,
[type='radio']:not(:checked) + span:after,
[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
    background: #fff;
}

#app input[type='text'].transparent {
    width: initial;
    border-bottom: none;
    margin: 0;
}

#app select.browser-default.transparent {
    border: none;
    width: 12%;
    height: initial;
    outline: none;
}
