* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #333333;
}

:root {
    --form-border-color: #dbdbdb;
    --eyesee-blue: #6699cc;
    --eyesee-purple: #996699;
    --eyesee-red: #ff3333;
    --eyesee-gray: #999999;
}

.text-eyesee-gray {
    color: var(--eyesee-gray);
}

.text-eyesee-blue {
    color: var(--eyesee-blue);
}

.text-eyesee-purple {
    color: var(--eyesee-purple);
}

.text-eyesee-red {
    color: var(--eyesee-red);
}

a {
    text-decoration: none;
}

.link {
    cursor: pointer;
}

.link:hover {
    opacity: 0.7;
}

li {
    list-style-type: none;
}

.sun-editor li,
.sun-editor ul li {
    list-style-type: inherit;
}

table {
    border: 1px solid #e0e0e0;
    border-radius: 3px;
}

thead {
    border-bottom: 1px solid #e0e0e0;
}

.page-wrapper {
    width: 70%;
    margin: auto;
    margin: 5rem auto;
}

.eyesee-blue {
    background: #6699cc;
}

.btn-small.eyesee-blue:hover {
    background: #4284c7;
}

.btn-secondary {
    border: none;
    background: white !important;
    color: #6699cc !important;
    box-shadow: none;
}

.loading-btn {
    display: flex;
    align-items: center;
}

.white-text {
    color: #fff;
}

.spinner-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    height: 100vh;
    width: 100vw;
    background-color: white;
}

.flex {
    display: flex;
}

.flex.space-between {
    justify-content: space-between;
}

.flex.align-center {
    align-items: center;
}

.w-full {
    width: 100%;
}

.w-half {
    width: 50%;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.flex-column {
    flex-direction: column;
}

option:disabled {
    background-color: #dbdbdb;
    color: rgba(0, 0, 0, 0.5);
}

.apart {
    margin-bottom: 10px !important;
}

#toast-container {
    top: 5%;
    /* max-height: 90vh;
  overflow-y: scroll;
  scrollbar-width: none;
  box-sizing: content-box;
  padding: 0px 50px;
  overflow-x: hidden; */
}
