.project-page-wrapper {
    background: #f3f6f9;
    min-height: 100vh;
}

#project {
    width: 70%;
    padding: 0 2rem;
    margin: 0 auto;
}

.slides {
    margin-top: 2rem;
}

.segment {
    margin: 3rem 0;
}

.segment:first-of-type {
    margin-top: 1.5rem;
}

.segment-section.row div {
    padding: 0;
}

.segment-section {
    margin: 1.5rem 0;
    padding: 0 2rem;
}
.click-switch {
    display: flex;
}
.click-switch label {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    white-space: nowrap;
}
.click-switch input {
    max-width: 50px;
}
.click-switch input[type='number']:disabled {
    cursor: not-allowed;
}
.click-switch input[type='number'] {
    padding: 10px;
}

.segment-title {
    display: flex;
    align-items: center;
    /* margin-left: 2rem; */
}
.segment-title > div {
    padding: 0px 2rem;
}

.segment-title i {
    margin-top: 4px;
    margin-right: 8px;
}

.segment-title h6 {
    font-weight: 700;
}

.languages {
    width: 15vw;
}

.links {
    display: flex;
    align-items: flex-end;
}

.links > .text-input {
    width: 20vw;
}

.links > .text-input:nth-child(2) {
    margin-left: 1vw;
}

.project-link {
    width: 300px;
}

.project-link:nth-child(2) {
    margin-left: 2em;
}

.project-ctrl-btns {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    display: flex;
    flex-direction: column;
}

.project-ctrl-btns .material-icons.disabled {
    pointer-events: none;
    color: var(--eyesee-gray);
}

.project-ctrl-btns .material-icons {
    font-size: 3rem;
    margin: 5px 0;
}

.project-details .sync-project {
    margin-left: 20px;
    color: var(--eyesee-blue);
    font-weight: bold;
    cursor: pointer;
    font-size: 26px;
}

.project-details .project-watermarked {
    color: #5b8d5d;
}

.project-details .sync-project.disabled {
    color: var(--eyesee-gray);
    cursor: default;
}
.exit-to-projects {
    width: 100%;
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.exit-to-projects span {
    font-size: 25px;
    transform: rotate(180deg);
    cursor: pointer;
}

.link-preview {
    position: fixed;
    box-shadow: 0 0 5px #cfcfcf;
    width: 570px;
    background: #fff;
    padding: 8px;
    z-index: 2;
    top: 20px;
    left: 20px;
    display: none;
    align-items: center;
}

.link-preview input[type='text']:not(.browser-default),
.link-preview input[type='text']:not(.browser-default):focus:not([readonly]) {
    margin-right: 5px;
    text-align: center;
    border-bottom: 0;
    box-shadow: 0 0 0 0 #d5efec;
    margin-bottom: 0 !important;
}

.link-preview .open-link {
    margin-top: 6px;
}

::selection {
    background: #d5efec;
}

i.link {
    opacity: 0.7;
    cursor: pointer;
}

i.link:hover {
    opacity: 1;
}

.introslide-lang {
    padding: 0px;
    margin: 10px 0px;
}

@media screen and (max-width: 1200px) {
    #project {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .project-ctrl-btns {
        right: 1rem;
    }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 750px) {
}
