.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
}

.per-page {
    display: flex;
    align-items: center;
}

.per-page select {
    height: 2.2em;
    font-size: 0.9em;
    flex: 0 0 50%;
}

.per-page span {
    font-size: 0.7em;
    flex: 0 0 60%;
    position: relative;
    left: 1em;
}
.pagination::after {
    display: block;
    content: '';
    clear: both;
}
.pagination {
    display: flex;
    justify-content: space-between;
}
.pagination button {
    margin: 0px 5px;
}
